<template>
  <div id="result_wrapper">
    <div class="content_box">
      <div class="inner_max">
        <div class="page-header">{{ $t('menu.depositFund') }}</div>
        <el-row>
          <el-col :span="24">
            <el-card class="p-3 p-md-5">
              <div
                class="result_content"
                v-show="waiting"
                v-loading="waiting"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(25, 35, 47, 0.3)"
              >
                <p>{{ $t('thirdPartyDeposit.result.countDownMsg', { countDown: countDown }) }}</p>
              </div>

              <div class="result_content" v-if="success">
                <i class="icon el-icon-circle-check text-green"></i>
                <p>{{ resultMessage }}</p>
                <router-link :to="{ name: 'home' }" class="el-button bg-primary">{{
                  $t('common.button.bkToHm')
                }}</router-link>
              </div>
              <div class="result_content" v-if="failure">
                <i class="icon el-icon-circle-close text-magenta"></i>
                <p>{{ resultMessage }}</p>
                <router-link :to="{ name: 'home' }" class="el-button bg-primary">{{
                  $t('common.button.bkToHm')
                }}</router-link>
              </div>
              <div class="result_content" v-if="info">
                <i class="icon el-icon-warning-outline text-magenta"></i>
                <p>{{ resultMessage }}</p>
                <router-link :to="{ name: 'home' }" class="el-button bg-primary">{{
                  $t('common.button.bkToHm')
                }}</router-link>
              </div>
              <div class="result_content" v-if="needUpload">
                <i class="icon el-icon-warning-outline text-magenta"></i>
                <el-form label-position="top" status-icon ref="paymentForm">
                  <div class="showForm">
                    <div class="form_last">
                      <p>{{ $t('deposit.cc.cardUpload') }}</p>
                      <el-row class="mb-5">
                        <el-col :sm="24" :md="12">
                          <vUpload :limit="1" v-on:updateFileInfo="updateFileInfo"></vUpload>
                        </el-col>
                        <el-col :sm="24" :md="12">
                          <img src="@/assets/images/channel/payment_card.png" alt />
                        </el-col>
                      </el-row>
                      <el-button @click="uploadImg()">
                        {{ $t('common.button.submit') }}
                      </el-button>
                    </div>
                  </div>
                </el-form>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { apiUpload_card, apiThirdPartyDepositResult } from '@/resource';
import vUpload from '@/components/vUpload';

export default {
  name: 'ThirdPartyDepositResult',
  data() {
    return {
      waiting: true,
      countDown: 30,
      resultMessage: '',
      successMessage: this.$t('thirdPartyDeposit.result.successMsg'),
      instantDepositMessage: this.$t('thirdPartyDeposit.result.message', {
        platform: this.$platform.info.fullName
      }),
      rejectMessage: this.$t('thirdPartyDeposit.result.rejectMsg'),
      failureMessage: this.$t('thirdPartyDeposit.result.failureMsg'),
      success: false,
      failure: false,
      info: false,
      fileList: [],
      needUpload: false,
      userId: '',
      paymentDepositId: '',
      excludeResultList: ['vload']
    };
  },
  components: { vUpload },
  methods: {
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
    },
    uploadImg(fileInfo) {
      apiUpload_card({
        paymentDepositId: this.paymentDepositId,
        userId: -1,
        fileList: this.fileList
      })
        .then(resp => {
          if (resp.data.data) {
            this.needUpload = false;
            this.success = true;
            this.resultMessage = this.instantDepositMessage;
          }
        })
        .catch(err => {
          this.$message({
            message: 'Upload failed. please try later.',
            type: 'error'
          });
        });
    },
    getPathName() {
      return window.location.pathname;
    },
    waitingCountDown() {
      setInterval(() => {
        this.countDown--;
      }, 1000);
    },
    excludeResult(paymentType) {
      return this.excludeResultList.includes(paymentType);
    },
    getPaymentType() {
      let paymentType = this.getPathName().split('/')[1];
      if (paymentType === 'thirdPartyDepositResult') paymentType = this.getPathName().split('/')[2];
      console.log(paymentType);
      this.getResult(paymentType);
    },
    getResult(paymentType) {
      if (!this.excludeResult(paymentType)) {
        this.postApiThirdPartyDepositResult(paymentType);
      } else {
        this.waiting = false;
        this.resultMessage = this.successMessage;
        this.success = true;
      }
    },
    postApiThirdPartyDepositResult(paymentType) {
      apiThirdPartyDepositResult(paymentType, this.$route.query)
        .then(result => {
          const status = result.data.data;
          if (
            paymentType == 'neteller' ||
            paymentType == 'paysafe_neteller' ||
            paymentType.toLowerCase() == 'paytrust'
          ) {
            console.log('neteller or paytrust in');
            this.resultMessage = this.successMessage;
            this.success = true;
          } else if (paymentType == 'skrill') {
            console.log('skrill');
            this.resultMessage = this.successMessage;
            this.success = true;
          } else if (
            paymentType == 'creditcard' ||
            paymentType == 'transact365' ||
            paymentType == 'solid' ||
            paymentType == 'apg' ||
            paymentType == 'sd'
          ) {
            if (result.data.data[0] == '1') {
              if (result.data.data[1] == '1') {
                this.needUpload = true;
                this.userId = result.data.data[2];
                this.paymentDepositId = result.data.data[3];
              } else {
                this.success = true;
                this.resultMessage = this.successMessage;
              }
            } else {
              this.failure = true;
              this.resultMessage = this.failureMessage;
            }
          } else if (paymentType == 'crypto' || paymentType == 'cryptousdt') {
            this.resultMessage = this.successMessage;
            this.success = true;
          } else if (paymentType == 'kr_df' || paymentType == 'bitwallet') {
            if (result.data.code == 0) {
              if (result.data.data[0] == '1') {
                this.resultMessage = this.successMessage;
                this.success = true;
              } else {
                this.failure = true;
                this.resultMessage = this.failureMessage;
              }
            } else {
              this.failure = true;
              this.resultMessage = this.failureMessage;
            }
          } else {
            if (result.data.code == 0) {
              if (status == '3') {
                // 支付失败 银行支付失败
                this.resultMessage = this.failureMessage;
                this.failure = true;
              } else if (status == '6') {
                // 手动审核 其他入金审核
                if (paymentType == 'unionpay') {
                  this.resultMessage = this.instantDepositMessage;
                  this.success = true;
                } else if (paymentType == 'cardpay' && result.data.msg == 'upload') {
                  this.paymentDepositId = result.data.extendInteger;
                  this.needUpload = true;
                } else {
                  this.resultMessage = this.defaultException;
                  this.info = true;
                }
              } else if (status == '5') {
                // mt4入金成功
                this.resultMessage = this.successMessage;
                this.success = true;
              } else if (status == '2') {
                //已提交到第三方，但未入金
                this.resultMessage = this.instantDepositMessage;
                this.success = true;
              } else if (status == '7') {
                // 拒绝
                this.resultMessage = this.rejectMessage;
                this.failure = true;
              } else {
                this.resultMessage = this.defaultException;
                if (paymentType == 'dollarsmart') {
                  this.info = true;
                } else {
                  this.failure = true;
                }
              }
            } else {
              console.log(result.data);
              this.resultMessage = this.defaultException;
              this.info = true;
            }
          }
          this.waiting = false;
        })
        .catch(err => {
          this.resultMessage = this.defaultException;
          this.failure = true;
          this.waiting = false;
        });
    }
  },
  mounted() {
    if (this.$route.meta.restrictApi) {
      this.waiting = false;
      this.resultMessage = this.successMessage;
      this.success = true;
    } else {
      this.waiting = true;
      this.waitingCountDown();

      this.getPaymentType();
    }
  },
  computed: {
    defaultException() {
      return this.$t('thirdPartyDeposit.result.defaultException', {
        platform: this.$platform.info.fullName,
        email: this.GLOBAL_CONTACT_EMAIL
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/thirdPartyDepositResult.scss';
</style>
